<template>
    <el-select v-model="clientStatus" class="w-30 ml-3" @change="clientStatusChange">
        <el-option value="" label="Alla" />
        <el-option value="active" label="Aktiv" />
        <el-option value="bankrupt" label="Konkurs" />
        <el-option value="leftus" label="Ej kund längre" />
        <el-option value="nocontact" label="Ej kontaktbar" />
        <el-option value="notready" label="Aktiv ej klar" />
        <el-option value="inactive" label="Inaktiverad" />
        <el-option value="reconstructtostock" label="Ombildat till AB" />
    </el-select>
</template>
<script>
import queryString from "query-string";
export default {
    data() {
        return {
            clientStatus: "Aktiv",
        };
    },

    created() {
        this.assignInitValues();
    },

    methods: {
        assignInitValues() {
            const { clientStatus } = queryString.parse(location.search);
            this.clientStatus = clientStatus || this.clientStatus;
        },

        clientStatusChange() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.clientStatus = this.clientStatus;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("change");
        },
    },
};
</script>
